body {
  padding: 0 !important;
  margin: 0 !important;
}
.main_components {
  background-color: var(--main-color);
  height: 70px;
}
.components_input {
  padding: 15px 10px 15px 32px;
  display: flex;
  justify-content: space-between;
}
.componentDate {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
}
.Input {
  border: 0;
  outline: none;
  height: 38px;
  width: 140px;
  background-color: var(--body-color);
  border-radius: 7px;
  text-align: center;
  color: white;
  cursor: pointer;
}
.Input::placeholder {
  color: #fff;
}
.MijozTulov {
  border: 0;
  outline: none;
  height: 38px;
  width: 140px;
  background-color: var(--body-color);
  border-radius: 7px;
  text-align: center;
  color: white;
  cursor: pointer;
}
.m_component {
  overflow: auto;
  z-index: 50 !important;
  width: 100%;
}
.mijozbody {
  width: 100%;
  display: flex;
  align-items: center;
  /* grid-gap: 2%; */
  background-color: #e5e5e5;
}
