.container_Login {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e5e5e5;
}
.mainLogin {
  max-width: 575px;
  min-width: 375px;
  padding: 20px;
  background-color: white;
  box-sizing: border-box;
  border-radius: 10px;
}
.gridLogin {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginInput {
  display: grid;
  grid-row-gap: 20px;
  border: none;
}
.Login {
  border-radius: 7px;
  padding: 8px;
  border: none;
  background-color: var(--type-color);
  outline: none;
}
.loginBtn {
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btnLogin {
  height: 30px;
  width: 100px;
  background-color: var(--main-color);
  border-radius: 5px;
}
