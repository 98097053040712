.aktsverkabody {
  min-height: 100vh;
  width: 100%;
  background-color: #e5e5e5;
}
body {
  padding: 0 !important;
  margin: 0 !important;
}
.mainAkt_components {
  background-color: var(--main-color);
  height: 70px;
  width: 100%;
}
.Aktcomponents_input {
  padding: 15px 10px 15px 32px;
  display: flex;
  justify-content: space-between;
}
.component_date {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-gap: 15px;
}
.Input {
  border: 0;
  outline: none;
  height: 38px;
  width: 140px;
  background-color: var(--body-color);
  border-color: var(--body-color);
  border-radius: 7px;
  text-align: center;
  color: white;
  cursor: pointer;
}
.Input::placeholder {
  color: #fff;
}
.AktTulov {
  border: 0;
  outline: none;
  height: 38px;
  width: 140px;
  background-color: var(--body-color);
  border-color: var(--body-color);
  border-radius: 7px;
  text-align: center;
  color: white;
  cursor: pointer;
}
