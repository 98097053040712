body {
  padding: 0 !important;
  margin: 0 !important;
}
.MainHeader {
  width: 100%;
  background-color: var(--body-color);
  font-family: sans-serif;
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  align-items: center;
  padding-left: 50px;
}
.mainLogotip {
  display: inline-block;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}
.brend {
  height: 35px;
  width: 115px;
}
.MainNavigation {
  display: inline-block;
  vertical-align: middle;
  justify-content: center;
}
.MainNavigationItems {
  margin: 0;
}
.MainNavigationItem1 {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  list-style: none;
  background-color: var(--body-color);
  text-align: center;
  align-items: center;
  justify-content: center;
}
.MainNavigationItem1Active {
  border-radius: 5px 5px 0px 0px;
  background-color: var(--main-color);
}
.MainNavigationItem1 a {
  color: rgb(224, 216, 216);
  text-decoration: none;
  text-transform: uppercase;
  font-family: Montserrat;
  font-style: normal;
  font-size: 18px;
  padding-right: 5px;
  padding-left: 5px;
  line-height: 20px;
}
.MainNavigationItem1 a:hover {
  color: white;
}
.MainNavigationItem1.active a {
  columns: var(--main-color);
}
.mainFunction {
  margin-right: 25px;
  display: flex;
  justify-content: space-between;
}
.icon {
  margin-left: 15px;
  cursor: pointer;
}
.mainLogout {
  max-width: 400px;
  min-width: 200px;
  background-color: rgb(202, 193, 193);
  box-sizing: border-box;
  border-radius: 7px;
}
.Textlog {
  padding-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logOutBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(202, 193, 193);
  min-height: 100px;
  min-width: 250px;
  border-radius: 7px;
}
.btnLogout {
  height: 30px;
  width: 100px;
  margin: 10px;
  background-color: var(--main-color);
  color: white;
  border-radius: 7px;
}
.containerLog {
  position: absolute;
  z-index: 100 !important;
  top: 55px;
  right: 10px;
}
