.bodymijoz {
  min-height: 100vh;
  width: 100%;
  background-color: #e5e5e5;
}
body {
  padding: 0 !important;
  margin: 0 !important;
}
.Mmain_components {
  background-color: var(--main-color);
  height: 70px;
  width: 100%;
}
.Mcomponents_input {
  padding: 15px 10px 15px 32px;
  display: flex;
  justify-content: space-between;
}
.Mcomponent_date {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-gap: 15px;
  align-items: center;
}
.Input {
  border: 0;
  outline: none;
  height: 38px;
  width: 140px;
  background-color: var(--body-color);
  border-radius: 7px;
  text-align: center;
  color: white;
  cursor: pointer;
}
.Input::placeholder {
  color: #fff;
}
.M_Mijoz {
  text-indent: 15px;
  border: none;
  color: white;
  height: 38px;
  border-radius: 5px;
  background-color: var(--body-color);
}
.QarzTulov {
  border: 0;
  outline: none;
  height: 38px;
  width: 140px;
  background-color: var(--body-color);
  border-color: var(--body-color);
  border-radius: 7px;
  text-align: center;
  color: white;
  cursor: pointer;
}
