.mainType {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.grid-1-1 {
  display: grid;
  grid-template-columns: auto 3fr auto auto;
  grid-gap: 16px;
  align-items: center;
  margin-bottom: 16px;
}
.grid-1-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  margin-bottom: 16px;
}
.grid-1-5 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  margin-bottom: 16px;
}
.summa::placeholder {
  color: white;
}
::placeholder {
  color: blue;
}
.grid-1 {
  display: grid;
  grid-template-columns: 2fr 3.5fr 2fr;
  grid-gap: 16px;
}
.mainbtn {
  display: grid;
  grid-gap: 20px;
  margin-top: 30px;
  grid-auto-flow: column;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.btnkontragent {
  margin-right: 20px;
  height: 40px;
  width: 200px;
  border: none;
  border-radius: 7px;
  cursor: pointer;
}
.s_naqd {
  text-indent: 15px;
  border: none;
  height: 30px;
  width: 18vh;
  border-radius: 5px;
  background-color: var(--type-color);
}
.Mijoz {
  text-indent: 15px;
  border: none;
  color: white;
  height: 30px;
  border-radius: 5px;
  background-color: var(--main-color);
}
.summa {
  text-indent: 15px;
  border: none;
  height: 30px;
  border-radius: 5px;
  background-color: var(--main-color);
}
.mijoz_izoh {
  text-indent: 15px;
  border: none;
  height: 30px;
  border-radius: 5px;
  background-color: var(--type-color);
}
.mijozQarz {
  text-indent: 15px;
  border: none;
  height: 30px;
  border-radius: 5px;
  background-color: var(--type-color);
}
.tulov_turi {
  text-indent: 15px;
  border: none;
  color: white;
  height: 30px;
  border-radius: 5px;
  background-color: var(--main-color);
}
